import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

// Connects to data-controller="project-selector"
export default class extends Controller {
  navigate(): void {
    const selectElement = this.element as HTMLSelectElement;
    const selectedValue = selectElement.selectedOptions[0]?.value;
    if (selectedValue) {
      Turbo.visit(selectedValue);
    }
  }
}
