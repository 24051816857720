import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="auto-submit"
export default class extends Controller {
  currentElement = this.element as HTMLInputElement | HTMLTextAreaElement;

  submit(): void {
    this.currentElement?.form?.requestSubmit();
  }

  submitWhenChanged(): void {
    if (this.currentElement.value !== this.currentElement.defaultValue) {
      this.currentElement.blur();
      this.currentElement?.form?.requestSubmit();
    }
  }

  submitWhenFormNotEmpty(): void {
    (this.element as HTMLInputElement).blur();
    const form = (this.element as HTMLInputElement)?.form;
    if (!form) return;

    const hasInput = Array.from(form.elements).some((element) => {
      return (
        ((element instanceof HTMLInputElement && element.type !== "hidden") || // do not submit CSRF token
          element instanceof HTMLTextAreaElement) &&
        element.value.trim().length > 0
      );
    });

    if (hasInput) {
      form.requestSubmit();
      form.reset();
    }
  }

  cancel(): void {
    this.currentElement.form?.reset();
    this.currentElement.blur();
  }
}
