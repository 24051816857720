import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="autogrow"
export default class extends Controller {
  connect(): void {
    this.resize();
  }

  input(): void {
    this.resize();
  }

  private resize(): void {
    const container = this.textArea.parentElement;
    if (container) {
      container.dataset.replicatedValue = this.textArea.value;
    }
  }

  private get textArea(): HTMLTextAreaElement {
    return this.element as HTMLTextAreaElement;
  }
}
