import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="translation"
export default class extends Controller {
  static values = { tooltipPath: String };
  static targets = ["tooltip", "textarea"];
  tooltipPathValue!: string;
  tooltipTarget!: HTMLElement;
  textareaTarget!: HTMLTextAreaElement;

  initialize(): void {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleTabOutside = this.handleTabOutside.bind(this);
  }

  connect(): void {
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("keyup", this.handleTabOutside);
  }

  disconnect(): void {
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("keyup", this.handleTabOutside);
  }

  handleTabOutside(event: KeyboardEvent): void {
    if (event.key === "Tab") {
      this.hideTooltipOnOutsideEvent(event);
    }
  }
  handleClickOutside(event: MouseEvent): void {
    this.hideTooltipOnOutsideEvent(event);
  }

  showTooltip(): void {
    this.tooltipTarget.setAttribute("src", this.tooltipPathValue);
  }

  submit(): void {
    if (this.textareaTarget.value !== this.textareaTarget.defaultValue) {
      this.textareaTarget.form?.requestSubmit();
    }
  }

  private hideTooltipOnOutsideEvent(event: MouseEvent | KeyboardEvent): void {
    if (!this.isTooltipVisible) {
      return;
    }

    const target = event.target as Node;

    if (
      target !== this.tooltipTarget &&
      target !== this.textareaTarget &&
      !this.tooltipTarget.contains(target) &&
      !this.textareaTarget.contains(target)
    ) {
      this.hideTooltip();
    }
  }

  submitAndBlur(): void {
    this.submitAndHideTooltip();
    this.blurTextarea();
  }

  cancel(): void {
    this.hideTooltip();
    this.textareaTarget.form?.reset();
    this.blurTextarea();
  }

  private submitAndHideTooltip(): void {
    this.submit();
    this.hideTooltip();
  }

  private hideTooltip(): void {
    this.tooltipTarget.innerHTML = "";
  }

  get isTooltipVisible(): boolean {
    return this.tooltipTarget.innerHTML !== "";
  }

  private blurTextarea(): void {
    this.textareaTarget.blur();
  }
}
