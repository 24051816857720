import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleable"];

  toggleableTarget!: HTMLElement;

  toggle(): void {
    this.toggleableTarget.classList.toggle("hidden");
  }
}
